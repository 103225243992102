<template>
  <div>
    <b-modal
      ref="modalDataCriteriosIdealista"
      :title="$t('Critérios de aplicabilidade Idealista')"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Fechar')"
      ok-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <p> {{ $t('O imóvel tem de estar no estado ativo.') }} </p>

      <p> {{ $t('O tipo de negócio definido deve estar de acordo ao tipo de negócio definido pelo seu HUB.') }} </p>

      <p v-if="tiposNegocio === ''">
        <strong> {{ $t('Não existem tipos de negócio definidos no seu HUB.') }} </strong>
      </p>

      <p v-if="tiposNegocio !== ''">
        {{ $t('Os tipos de negócio definidos no seu HUB são:') }} <strong> {{ tiposNegocio }} </strong>
      </p>

      <p> {{ $t('O tipo de imóvel definido deve estar de acordo ao tipo de imóvel definido pelo seu HUB.') }} </p>

      <p v-if="tiposImovel === ''">
        <strong> {{ $t('Não existem tipos de imóvel definidos no seu HUB.') }} </strong>
      </p>

      <p v-if="tiposImovel !== ''">
        {{ $t('Os tipos de imóvel definidos no seu HUB são:') }} <strong> {{ tiposImovel }} </strong>
      </p>

      <p> {{ $t('O preço tem de estar definido.') }} {{ $t('A publicação do preço do imóvel é obrigatória.') }}</p>

      <p> {{ $t('A rua tem de estar definida.') }} </p>

      <p> {{ $t('O código postal tem de estar definido.') }} </p>

      <p> {{ $t('Uma das seguintes áreas tem de estar definida: área útil, área bruta ou área do terreno.') }} </p>

      <p> {{ $t('Se o tipo de negócio definido é "Arrendamento" ou "Trespasse" então o valor da renda tem de estar definido e deve ser superior a 1.') }} </p>

      <p v-if="iso === 'PT'">
        {{ $t('e o tipo de negócio definido é "Trespasse" o tipo de imóvel só pode ser: Loja, Pavilhão, Armazém ou Comércio.') }}
      </p>

      <p v-if="iso === 'ES'">
        {{ $t('Se o tipo de negócio definido é "Trespasse" o tipo de imóvel só pode ser: Local, Nave ou Trastero.') }}
      </p>

      <p> {{ $t('Condição do imóvel não pode ser "Não Aplicável"') }} </p>

      <p> {{ $t('A área de Terreno não pode exceder 99999999 se o tipo de imóvel for do tipo: Terreno') }} </p>

      <p> {{ $t('A área bruta de construção tem de estar compreendida entre 11 e 999999 nos seguintes tipos de imóveis: Moradia, Apartamento, Escritório, Pavilhão, Armazém, Comércio, Edifício, Prédio, Quinta, Solar, Palacete') }} </p>

      {{ $t('É obrigatório ter casas de banho ou a Condição do imóvel tem de ser do tipo "Para Recuperar" ou "Ruína" nos seguintes tipos de imóveis: Moradia, Quinta, Solar, Palacete, Herdade e Apartamentos') }}

      <p v-if="iso === 'PT'">
        {{ $t('É obrigatório ter quartos ou a Condição do imóvel tem de ser do tipo "Para Recuperar" ou "Ruína" nos seguintes tipos de imóveis: Moradia, Quinta, Solar, Palacete, Herdade e Apartamento do tipo Andar/Moradia') }}
      </p>

      <p> {{ $t('A área de construção de um terreno não pode ser maior do que a sua área de terreno caso contrário o valor da área de construção não é passado.') }} </p>

      <p> {{ $t('A condição do imóvel "Em construção" será comunicada ao idealista como "Novo"') }} </p>

      <p v-if="iso === 'PT'">
        {{ $t('A área útil não pode ser superior ou igual á área de construção caso contrário o valor da área útil não é passado nos seguintes tipos de imóveis: Loja, Pavilhão, Armazém, Comércio, Moradias e Apartamentos') }}
      </p>
      <p v-if="iso === 'ES'">
        {{ $t('A área útil não pode ser superior ou igual á área de construção caso contrário o valor da área útil não é passado nos seguintes tipos de imóveis: Local, Nave, Trastero, Vivienda, Chalet, Piso/Apartamento') }}
      </p>

      <p> {{ $t('Qualquer alteração de dados no imóvel só será atualizado no portal na próxima rotina.') }} </p>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    tiposImovel: {
      type: String,
      default: () => '',
    },
    tiposNegocio: {
      type: String,
      default: () => '',
    },
    iso: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataCriteriosIdealista.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataCriteriosIdealista.hide()
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
